import React from 'react'
import { PageProps } from 'gatsby'
import { Head, Footer } from '~/components'
import * as st from '~/assets/styl/PageDefault.module.styl'
import img from '~/assets/img/img-manual.png'
import cn from 'classnames'

const ManualRemuneracao = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <section className="container">
          <h1>Manual de remuneração</h1>
          <p>
            Entenda por que escrevemos este manual e para quem ele se aplica
          </p>
        </section>
        <div className={cn(st.imgText, st.purple, st.largeVersion)}>
          <img src={img} alt="" />
          <div className={st.littleFont}>
            <ul>
              <li>
                <p>Por que escrevemos este manual?</p>
                <p>
                  Para apresentar a estratégia de remuneração do Sistema Unicred
                  Conexão.
                </p>
              </li>
              <li>
                <p>Para quem se aplica este manual de remuneração?</p>
                <p>
                  Para todos os colaboradores com contrato em regime CLT do
                  Sistema Unicred Conexão.
                </p>
              </li>
            </ul>
            <a
              href="https://admin.pccrcentralconexao.com.br/api/page-download"
              target="_blank"
              rel="noreferrer"
            >
              Download do manual
            </a>
          </div>
        </div>
      </main>
      <Footer location={location} />
    </>
  )
}

export default ManualRemuneracao
